import $ from 'cash-dom';

$(window).on('load', () => {
  let isBot = false;

  function init() {
    initListeners();
    initLabels();
    determineBot($('.up-form-field.is-botcheck input'));
  }

  function initListeners() {
    $('.up-form-field:not(.is-phone) input').on('input', function (event) {
      positionLabel($(event.currentTarget));
    });

    $('.up-form-field input').on('keydown', function (event) {
      if (event.key == 'Enter' && isBot) {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
      }
    });

    $('#up-form submit').on('keypress', function (event) {
      if (event.key == 'Enter' && isBot) {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
      }
    });

    $('.up-form-field.is-botcheck input').on('input', function (event) {
      determineBot($(event.currentTarget));
    });

    $('#up-form').on('submit', function (event) {
      if (isBot) {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
      }
    });
  }

  function initLabels() {
    $('.up-form-field input').each(function (index, element) {
      positionLabel($(element));
    });
  }

  function determineBot(input) {
    if (input.val() != '') {
      isBot = true;
      input.parent().parent().find('.submit-button').addClass('is-disabled').find('button').attr('tabindex', -1);
    } else {
      isBot = false;
      input.parent().parent().find('.submit-button').removeClass('is-disabled').find('button').removeAttr('tabindex');
    }
  }

  function positionLabel(element) {
    const input = $(element);

    if (input.val() != '') {
      input.closest('.up-form-field').addClass('has-input');
    } else {
      input.closest('.up-form-field').removeClass('has-input');
    }
  }

  init();
});
