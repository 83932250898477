import $ from 'cash-dom';

$(window).on('load', () => {
  function init() {
    initListeners();
  }

  function initListeners() {
    $('.bof-reader-menu-item-heading').on('click', function (event) {
      event.preventDefault();

      $(this).toggleClass('is-active');
      $(this).parent().find('.bof-reader-menu-item-child').toggleClass('is-active');
    });

    $('.bof-reader-menu-item-child-heading').on('click', function (event) {
      event.preventDefault();

      $(this).toggleClass('is-active');
      $(this).parent().find('.bof-reader-menu-item-child-items').toggleClass('is-active');
    });

    $('.bof-reader-mobile-menu-link').on('click', function (event) {
      event.preventDefault();

      $('body').toggleClass('is-modal-active');
      $('.bof-reader-mobile-menu-wrapper').toggleClass('is-active');
      $('.bof-reader-mobile-menu-link-icon').toggleClass('is-active');
    });

    $('.bof-reader-content-category-toggle.is-show-all').on('click', function (event) {
      event.preventDefault();

      $(this).removeClass('is-active');
      $('.bof-reader-content-category-toggle.is-show-less').addClass('is-active');
      $('.bof-reader-content-category-item.is-overflow').removeClass('is-hidden');
    });

    $('.bof-reader-content-category-toggle.is-show-less').on('click', function (event) {
      event.preventDefault();

      $(this).removeClass('is-active');
      $('.bof-reader-content-category-toggle.is-show-all').addClass('is-active');
      $('.bof-reader-content-category-item.is-overflow').addClass('is-hidden');
    });
  }

  init();
});
