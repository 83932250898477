import $ from 'cash-dom'

function showMega() {
  const mega = $('#productsMega')
  mega.addClass('is-active')
  $('html').addClass('is-modal_mode')

  setTimeout(() => {
    $('body').on('click.megaMenu', () => {
      hideMega()
    })
  }, 100)
}

function hideMega() {
  const mega = $('#productsMega')
  mega.removeClass('is-active')
  $('html').removeClass('is-modal_mode')

  $('body').off('click.megaMenu')
}

$(document).on('click', '.siteheader-toggle', (e) => {
  $('.sitenav').toggleClass('is-active')
  $('body').addClass('is-modal-active')
  $('.bof-reader-mobile-menu-wrapper').removeClass('is-active');
  $('.bof-reader-mobile-menu-link-icon').removeClass('is-active');
})

$(document).on('click', '.sitenav-close', (e) => {
  $('.sitenav').removeClass('is-active')
  $('body').removeClass('is-modal-active')
})

$(document).on('click', '.sitenav-item.is-products', (e) => {
  e.preventDefault()
  e.stopPropagation()
  
  const mega = $('#productsMega')
  if (!mega.hasClass('is-active')) {
    showMega(mega)
    $(e.target).addClass()
  } else {
    hideMega()
  }

  // mega.toggleClass('is-active')
  // $('html').toggleClass('is-modal_mode', mega.hasClass('is-active'))

  // $('body').on('click', () => {
  //   $('html').removeclass('is-modal_mode')
  // })
})

$(document).on('click', '.mega-backlink-button', hideMega)


