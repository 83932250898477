// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as ActiveStorage from '@rails/activestorage';
import Rails from '@rails/ujs';
import 'channels';
// import Turbolinks from 'turbolinks';

Rails.start();
// Turbolinks.start();
ActiveStorage.start();

document.querySelector('html').classList.add('js');

import 'rails/accordion';
import 'rails/cases';
import 'rails/dropdown';
import 'rails/faq';
import 'rails/features';
import 'rails/hero';
import 'rails/knowledgeEntry';
// import 'rails/logos'
import 'rails/map';
import 'rails/message';
import 'rails/parallax';
import 'rails/prices';
import 'rails/reader';
import 'rails/scrollable';
import 'rails/section';
import 'rails/siteHeader';
import 'rails/sitenav';
import 'rails/smooth-scroll';
import 'rails/statement';
import 'rails/subscribe';
import 'rails/testimonials';
import 'rails/up-form';
