import $ from 'cash-dom';

function init() {
  let oldPosition = 0;

  $(window).on('scroll', () => onScroll(window.scrollY));

  function onScroll(scrollPos) {
    const threshold = document.querySelector('.siteheader').clientHeight;
    const thresholdTwo = threshold + 100;

    let isScrolledDown = false;
    let isShowing = false;
    let isHiding = false;
    let hasBackgroundColor = false;

    isShowing = scrollPos > threshold && oldPosition > scrollPos;
    isHiding = scrollPos > threshold && oldPosition < scrollPos;
    hasBackgroundColor = scrollPos > thresholdTwo;
    isScrolledDown = scrollPos > threshold;

    $('.siteheader')
      .toggleClass('is-scrolled_down', isScrolledDown)
      .toggleClass('is-showing', isShowing)
      .toggleClass('is-hiding', isHiding)
      .toggleClass('has-background-color', hasBackgroundColor);

    let readerMenu = document.querySelector('.bof-reader-menu');

    if (readerMenu != null || readerMenu != undefined) {
      $('.bof-reader-menu').toggleClass('siteheader-is-showing', isShowing);
      $('.bof-reader-details').toggleClass('siteheader-is-showing', isShowing);
      $('.bof-reader-mobile-menu').toggleClass('is-scrolled-down', isScrolledDown);
      $('.bof-reader-mobile-menu').toggleClass('is-showing', isShowing);
      $('.bof-reader-mobile-menu').toggleClass('is-hiding', isHiding);
    }

    oldPosition = scrollPos;
  }
}

$(window).on('load', init);
