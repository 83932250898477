import $ from 'cash-dom';

let url = null;
let searchTimeout = null;
let query = null;
let dropdown = null;
let searchResultItems = [];
let lang = document.documentElement.lang;

function search(e) {
  if (!e.target.value || (e.target.value && e.target.value.length < 2)) {
    cancelSearch($(e.target));
    return;
  }

  if (searchTimeout) {
    clearTimeout(searchTimeout);
  }

  searchTimeout = setTimeout(() => {
    if (query === e.target.value) {
      return;
    }

    query = e.target.value;
    performSearch($(e.target), query);
  }, 1000);
}

function performSearch(inputElement, query) {
  fetch(`${inputElement.closest('.bof-search').attr('action')}?q=${query}`)
    .then((response) => response.json())
    .then((data) => {
      searchResultItems = data;
      addItemsToDropdown(inputElement);
    });
}

function cancelSearch(inputElement) {
  if (searchTimeout) {
    clearTimeout(searchTimeout);
  }

  hideDropdown(inputElement);
  searchResultItems = [];
  query = null;
}

function addItemsToDropdown(inputElement) {
  let noResultsText = lang === 'sv' ? 'Din sökning gav inga resultat.' : 'Your search did not return any results.';

  let html = '';

  searchResultItems.each((item) => {
    html += `<a href="${item.url}" class="search-result-item">
              <h4 class="search-result-item-heading">${item.title}</h4>
              <p class="search-result-item-meta">${item.category.title}</p>
            </a>`;
  });

  inputElement.parent().parent().find('.bof-search-results').html(html);

  if (searchResultItems.length > 0) {
    showDropdown(inputElement);
  } else {
    showDropdown(inputElement);
    let html = '';
    html += `<div class="search-result-item">
              <p class="search-result-item-meta">${noResultsText}</p>
            </div>`;

    inputElement.parent().parent().find('.bof-search-results').html(html);
  }
}

function showDropdown(inputElement) {
  console.log(inputElement);
  console.log(inputElement.parent().parent());
  inputElement.parent().parent().find('.bof-search-results').addClass('is-active');

  setTimeout(() => {
    $(document)
      .off('click.searchDropdown')
      .on('click.searchDropdown', () => {
        hideDropdown(inputElement);
      });
  }, 100);
}

function hideDropdown(inputElement) {
  inputElement.parent().parent().find('.bof-search-results').removeClass('is-active');
  $(document).off('click.searchDropdown');
}

function toggleCategory(category, button) {
  $('.bof-knowledge-center-search-results-filter-link').removeClass('is-active');
  button.addClass('is-active');

  if (category != '*') {
    $('.bof-knowledge-center-search-results-link').addClass('is-hidden');
    $(`.bof-knowledge-center-search-results-link[data-id="${category}"`).removeClass('is-hidden');
  } else {
    $('.bof-knowledge-center-search-results-link').removeClass('is-hidden');
  }
}

//function init() {
//if (document.getElementById('knowledgeEntrySearchForm')) {
//  url = document.getElementById('knowledgeEntrySearchForm').action;
//  //dropdown = $('#knowledgeEntrisSearchResult');
//}
//if (document.getElementById('knowledgeEntrySearchForm')) {
//  url = document.getElementById('knowledgeEntrySearchForm').action;
//  //dropdown = $('#knowledgeEntrisSearchResult');
//}
//}

// $(document).on('focus', '.bof-search .bof-search-input', (e) => {
//   if (e.target.value && searchResultItems.length > 0) {
//     showDropdown($(e.target));
//   }
// });

//$(document).on('search', '.bof-search .bof-search-input', (e) => {
//  // cancelSearch($(e.target));
//  // performSearch($(e.target), query);
//  query = e.target.value;
//  url = lang === 'sv' ? '/sv/knowledge-center/search' : '/en/knowledge-center/search';

//  console.log(url);
//  //window.location.href = `${url}?q=${query}`;
//});

function executeSearch(inputElement) {
  const query = inputElement.val();
  const category = inputElement.attr('data-category');
  const url = lang === 'sv' ? '/sv/knowledge-center/search' : '/en/knowledge-center/search';

  if (category == '') {
    window.location.href = `${url}?q=${query}`;
  } else {
    window.location.href = `${url}?q=${query}&c=${category}`;
  }
}

$(document).on('submit', '.bof-search', (e) => {
  e.preventDefault();
  executeSearch($(e.target).find('.bof-search-input'));
});

$(document).on('click', '.bof-search .bof-search-icon', (e) => {
  e.preventDefault();
  executeSearch($(e.target).parent().find('.bof-search-input'));
});

$(document).on('click', '.bof-knowledge-center-search-results-filter-link', function (event) {
  event.preventDefault();
  toggleCategory($(this).attr('data-id'), $(this));
});

// $(document).on('submit', '.bof-search, .bof-search-input', (e) => {
//   cancelSearch($(e.target));
//   query = e.target.value;
//   console.log(query);

//   performSearch($(e.target), query);
//   // e.preventDefault();
// });

//$(window).on('load', init);
//$(document).on('input', '#knowledgeEntrySearchInput', search);
// $(document).on('input', '.bof-search .bof-search-input', search);
